import { Component, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Plugins, StatusBarStyle, Capacitor } from '@capacitor/core';
const { StatusBar, SplashScreen } = Plugins;
import * as moment from 'moment';
import { Router, NavigationStart } from '@angular/router';
import { MenuController } from '@ionic/angular';
import './interfaces';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(
      private platform: Platform,
      private router: Router,
      private menu: MenuController,
  ) {
    this.initializeApp();
  }

  async initializeApp() {
    await this.platform.ready();
    this.menu.enable(true, 'mainMenu');
    this.router.events.subscribe((events) => {
      if (events instanceof NavigationStart) {
        if (events.url === '/') {
          this.menu.enable(false, 'mainMenu');
        } else {
          this.menu.enable(true, 'mainMenu');
        }
      }
    });
    if (Capacitor.platform !== 'web') {
      try {
        await StatusBar.setStyle({ style: StatusBarStyle.Dark });
        await StatusBar.setBackgroundColor({ color: '#F37335' });
      } catch (Err) {
      }
    }
  }

  ngOnInit() {
  }
}
