import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'companies',
    loadChildren: () => import('./pages/company/company-list/company-list.module').then( m => m.CompanyListPageModule)
  },
  {
    path: 'companies/:CompanyId',
    loadChildren: () => import('./pages/company/company-detail/company-detail.module').then( m => m.CompanyDetailPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'certificate',
    loadChildren: () => import('./pages/certificate/certificate.module').then( m => m.CertificatePageModule)
  },
  {
    path: 'business-companies/:CompanyId',
    loadChildren: () => import('./pages/business-company/business-company-detail/business-company-detail.module').then( m => m.BusinessCompanyDetailPageModule)
  },
  {
    path: 'business-companies',
    loadChildren: () => import('./pages/business-company/business-company-list/business-company-list.module').then( m => m.BusinessCompanyListPageModule)
  },
  {
    path: 'statistics',
    loadChildren: () => import('./pages/statistics/statistics.module').then( m => m.StatisticsPageModule)
  },  {
    path: 'csv',
    loadChildren: () => import('./pages/csv/csv.module').then( m => m.CsvPageModule)
  },
  {
    path: 'report',
    loadChildren: () => import('./pages/report/report.module').then( m => m.ReportPageModule)
  },
  {
    path: 'test',
    loadChildren: () => import('./pages/test/test.module').then( m => m.TestPageModule)
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
