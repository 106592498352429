import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';

if (location.href.indexOf('4200') === -1) {
  enableProdMode();
}

if (location && location.search.indexOf('?xapikey') === 0) {
  localStorage.setItem('x-api-key', location.search.split('?xapikey=')[1]);
}

platformBrowserDynamic().bootstrapModule(AppModule).catch(err => console.log(err));

const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');

toggleDarkTheme(prefersDark.matches);

// Listen for changes to the prefers-color-scheme media query
prefersDark.addListener((mediaQuery) => toggleDarkTheme(mediaQuery.matches));

// Add or remove the "dark" class based on if the media query matches
function toggleDarkTheme(shouldAdd) {
  document.body.classList.toggle('dark', shouldAdd);
}
