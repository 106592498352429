import { Router } from '@angular/router';
import { AuthService } from './../../services/auth.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {

  User = false;

  constructor(private authService: AuthService, private router: Router) { }

  async ngOnInit() {
    this.User = await this.authService.getUser();
    if(!this.User) {
      this.router.navigateByUrl('/login')
    }
  }
  logout() {
    this.authService.logout();
  }
  login() {
    this.authService.login();
  }

}
