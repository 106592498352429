import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headers = {
      'Content-Type': 'application/json; charset=utf-8',
      Accept: 'application/json',
    };
    if (localStorage.getItem('company-id')) {
      headers['company-id'] = localStorage.getItem('company-id');
    }
    if (localStorage.getItem('x-api-key')) {
      headers['x-api-key'] = localStorage.getItem('x-api-key');
    }
    req = req.clone({
      setHeaders: headers,
    });

    return next.handle(req);
  }
}
